(function ($) {
    'use strict';

    function monthlyPayment(p, n, i) {
        return p * i * (Math.pow(1 + i, n)) / (Math.pow(1 + i, n) - 1);
    }

    // Initialize collapse button
    $('.button-collapse').sideNav({
            menuWidth: 300, // Default is 300
            edge: 'right', // Choose the horizontal origin
            closeOnClick: true, // Closes side-nav on <a> clicks, useful for Angular/Meteor
            draggable: true, // Choose whether you can drag to open on touch screens
            onOpen: function (el) {
                $('#sidenav-overlay').css('display', 'block');
            },
            onClose: function (el) {
                $('#sidenav-overlay').css('display', 'none');
            }
        }
    );

    // Initialize collapsible
    $('.collapsible').collapsible();

    var container = $("#slide-out");
    $(window).click(function () {
        $('.button-collapse').sideNav('hide');
    });

    $(container).click(function (event) {
        event.stopPropagation();
    });

    // Mobile responsive nav calculation
    function responsiveNav() {
        var brokeWidth = 0;

        function hideNav() {
            var width = $('#nav-main .container').width();
            var breakWidth = 0;
            $('#nav-main .container > ul > li').each(function (index, el) {
                breakWidth += $(this).outerWidth();
            });

            //console.log(width + ' | ' + breakWidth);

            if (breakWidth >= width && brokeWidth <= 0) {
                brokeWidth = breakWidth;
                $('#nav-main .container > ul > li').hide();
                // $('#nav-main .container').attr('style','line-height:0;');
                $('.button-collapse').attr('style', 'display: inline-block !important;');
            }

            if (width > brokeWidth) {
                $('#nav-main .container > ul > li').show();
                // $('#nav-main .container').attr('style','line-height:auto;');
                $('.button-collapse').attr('style', 'display: none !important;');
                brokeWidth = 0;
            }
        }

        $(window).resize(function (event) {
            hideNav();
        });

        $(document).ready(function ($) {
            hideNav();
        });
        $('#nav-main .container').css('opacity', '1');
    }

    responsiveNav();

    // Dropdown in navigation
    var navCount = 1;
    $('#nav-main .container > ul > li.menu-item-has-children > a').each(function () {
        $(this).addClass('dropdown-button');
        $(this).next('ul').attr({id: 'dropdown' + navCount, class: 'dropdown-content'});
        navCount += 1;
    });

    $('body.home .home-flexslider').flexslider({
        animation: "fade",
        directionNav: false,
        controlNav: false,
        slideshowSpeed: 8000
    });

    $('body.home .testimonial-flexslider').flexslider({
        animation: "slide",
        directionNav: false,
        controlNav: true,
        slideshowSpeed: 10000
    });

    /**
     * @function mortgageCalculator
     * @description Looks for an element with the id "mortgage-calculator" and creates a form at its position.
     * @author Eric Fraze
     */
    function mortgageCalculator() {
        var calculator = $('#mortgage-calculator');
        if (calculator.length > 0) {
            var M; //monthly mortgage payment
            var P = 400000; //principle / initial amount borrowed
            var I = 3.5 / 100 / 12; //monthly interest rate
            var N = 30 * 12; //number of payments months

            //monthly mortgage payment
            M = monthlyPayment(P, N, I);


            calculator.replaceWith('<form id="mortgage-calculator" onsubmit="event.preventDefault();">');
            calculator = $('#mortgage-calculator');
            calculator.append('<label class="bcorr-mort-label" id="loan-label" for="loan">Loan Amount ($)</label>');
            calculator.append('<input class="required" id="loan" type="number" min="0" step="0.01" name="loan" required="required" data-message="A loan ammount is required.">');
            calculator.append('<label class="bcorr-mort-label" id="down-label" for="down">Down Payment ($)</label>');
            calculator.append('<input class="required" id="down" type="number" min="0" step="0.01" name="down">');
            calculator.append('<label class="bcorr-mort-label" id="rate-label" for="rate">Interest Rate (%)</label>');
            calculator.append('<input class="required" id="rate" type="number" step="0.01" min="0" name="rate" required="required" data-message="An interest rate is required.">');
            calculator.append('<label class="bcorr-mort-label" id="length-label" for="length">Loan Term (Years)</label>');
            calculator.append('<input class="required" id="length" type="number" min="0" step="1" name="length" required="required" data-message="A loan length is required.">');
            calculator.append('<div class="monthly">Monthly payment: <div id="monthly"></div></div>');
            calculator.append('<div><input type="submit" id="mortgage-calculator-submit" value="Calculate" class="button-primary"><a class="button-primary" id="bcorr-reset">Reset Calculator</a></div>');

            $('#mortgage-calculator-submit').click(function () {
                var loan = parseFloat($('#loan').val().replace(/[^0-9\.]+/g, ""));
                var down = parseFloat($('#down').val().replace(/[^0-9\.]+/g, ""));
                var rate = parseFloat($('#rate').val().replace(/[^0-9\.]+/g, ""));
                var years = parseFloat($('#length').val().replace(/[^0-9\.]+/g, ""));

                if (isNaN(down)) {
                    down = 0;
                }

                loan = loan - down;
                rate = rate / 100 / 12;
                var months = years * 12;
                var monthly = monthlyPayment(loan, months, rate);
                if (isNaN(monthly)) {
                    $('#monthly').text('');
                } else {
                    monthly = '$' + monthly.toFixed(2).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,");
                    $('#monthly').text(monthly);
                }
            });

            $('#bcorr-reset').click(function () {
                $('#loan').val('');
                $('#down').val('');
                $('#rate').val('');
                $('#length').val('');
                $('#monthly').text('');
            });
        }
    }

    mortgageCalculator();

    // moblie slide out nav accordian
    var sideNavCount = 1;
    $('#slide-out > ul').attr('data-collapsible', 'accordian');
    $('#slide-out > ul').addClass('collapsible');
    $('#slide-out > ul > li.menu-item-has-children > a').each(function () {
        $(this).addClass('collapsible-header').append('<i class="fa fa-caret-down"></i>');
        $(this).next('ul').addClass('collapsible-body');
        $(this).click(function () {
            $(this).next('ul').toggleClass('show-subnav');
            return false;
        });
        sideNavCount += 1;
    });
    $(document).ready(function () {
        $('.collapsible').collapsible();
    });

    // initialize all carousels
    $('.bcorr-hero .carousel.carousel-slider').carousel({fullWidth: true, indicators: true});
    $('.bcorr-hero .carousel').carousel();
    if ($('.bcorr-hero .carousel .carousel-item').length > 1) {
        setInterval(function () {
            $('.bcorr-hero .carousel').carousel('next');
        }, 10000);
    }

    $('.bcorr-hero .carousel .carousel-item').each(function () {
        var $container = $(this),
            imgUrl = $container.find('img').prop('src');
        if (imgUrl) {
            $container.css('backgroundImage', 'url(' + imgUrl + ')');
        }
    });

    // move wolfnet legal disclaimer
    if ($('.wolfnet_marketDisclaimer').length) {
        $('.wolfnet_marketDisclaimer').insertBefore('#footer').wrapInner('<div class="container"></div>');
    }

    if ($('#wnt-wrap').length) {
        $('body').addClass('bcorr-wolfnet');
    }

    // Guide Image
    function proofguide(proof) {
        var button = $('<div id="bcorr-guide-toggle">');
        var guide = $('<div id="bcorr-guide" class="hide">');
        var img = $('<img>');
        img.attr('src', proof);
        img.appendTo(guide);

        guide.appendTo('body');
        button.appendTo('body');

        $("#bcorr-guide-toggle").on('click', function (event) {
            $("#bcorr-guide").toggleClass("hide");
        });
        $("#bcorr-guide img").on('click', function (event) {
            $("#bcorr-guide").toggleClass("light");
        });

        $(window).resize(function () {
            var screenImage = $("#bcorr-guide img");

            // Create new offscreen image to test
            var theImage = new Image();
            theImage.src = screenImage.attr("src");

            // Get accurate measurements from that.
            var imageWidth = theImage.width;
            if (imageWidth > $(window).width()) {
                $("#bcorr-guide img").css("-webkit-filter", "invert(1)");
            } else {
                $("#bcorr-guide img").css("-webkit-filter", "none");
            }
        });
    }

    // proofguide('/wp-content/themes/stephanie-laker/resources/guide.jpg');

})(jQuery);
